$ = jQuery.noConflict();


$(window).on("load", function() {

    "use strict";

    /* ===================================
            Loading Timeout
     ====================================== */

    // setTimeout(function() {
    //     $(".loader").fadeOut("slow");
    // }, 1000);

});

jQuery(function($) {


    "use strict";


    /* ===================================
            Scroll
    ====================================== */


    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 220) { // Set position from top to add class
            $('header').addClass('header-appear');
        } else {
            $('header').removeClass('header-appear');
        }
    });


    $(".progress-bar").each(function() {
        $(this).appear(function() {
            $(this).animate({ width: $(this).attr("aria-valuenow") + "%" }, 3000)
        });
    });


    $('.count').each(function() {
        $(this).appear(function() {
            $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
            }, {
                duration: 3000,
                easing: 'swing',
                step: function(now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });
    });


    //scroll to appear
    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 500)
            $('.scroll-top-arrow').fadeIn('slow');
        else
            $('.scroll-top-arrow').fadeOut('slow');
    });

    // fixing bottom nav to top on scrolliing
    var $fixednav = $(".bottom-nav");
    $(window).on("scroll", function() {
        var $heightcalc = $(window).height() - $fixednav.height();
        if ($(this).scrollTop() > $heightcalc) {
            $fixednav.addClass("navbar-bottom-top");
        } else {
            $fixednav.removeClass("navbar-bottom-top");
        }
    });

    //Click event to scroll to top
    $(document).on('click', '.scroll-top-arrow', function() {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });


    //scroll sections
    if ($("body").hasClass("intrective")) {
        $(".scroll").on("click", function(event) {
            event.preventDefault();
            $("html,body").animate({
                scrollTop: $(this.hash).offset().top
            }, 1200);
        });

    } else {

        $(".scroll").on("click", function(event) {
            event.preventDefault();
            $("html,body").animate({
                scrollTop: $(this.hash).offset().top - 60
            }, 1200);
        });

    }

    /* =====================================
           Parallax
    ====================================== */

    if ($(window).width() > 992) {
        $(".parallax").parallaxie({
            speed: 0.55,
            offset: 0,
        });
    }


    /* ===================================
       Side Menu
   ====================================== */
    // if ($("#sidemenu_toggle").length) {
    //     $("#sidemenu_toggle").on("click", function() {
    //         $(".pushwrap").toggleClass("active");
    //         $(".side-menu").addClass("side-menu-active"), $("#close_side_menu").fadeIn(700)
    //     }), $("#close_side_menu").on("click", function() {
    //         $(".side-menu").removeClass("side-menu-active"), $(this).fadeOut(200), $(".pushwrap").removeClass("active")
    //     }), $(".side-nav .navbar-nav .nav-link").on("click", function() {
    //         $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
    //     }), $("#btn_sideNavClose").on("click", function() {
    //         $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
    //     });
    // }

    $("#sidemenu_toggle").on("click", function() {
        $(".pushwrap").toggleClass("active");
        $(".side-menu").addClass("side-menu-active"), $("#close_side_menu").fadeIn(700);
    });

    $("#close_side_menu").on("click", function() {
        $(".side-menu").removeClass("side-menu-active"), $(this).fadeOut(200), $(".pushwrap").removeClass("active");
    });

    $(".side-nav .navbar-nav .nav-link").on("click", function() {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active");
    });

    $("#btn_sideNavClose").on("click", function() {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active");
    });

    if ($(".side-right-btn").length) {

        $(".side-right-btn").on('click', function() {
            $(".navbar.navbar-right").toggleClass('show');
        });

        $(".navbar.navbar-right .navbar-nav .nav-link").on('click', function() {
            $(".navbar.navbar-right").toggleClass('show');
        });
    }


    /* ===================================
      Rotating Text
      ====================================== */

    if ($("#js-rotating").length) {
        $("#js-rotating").Morphext({
            // The [in] animation type. Refer to Animate.css for a list of available animations.
            animation: "flipInX",
            // An array of phrases to rotate are created based on this separator. Change it if you wish to separate the phrases differently (e.g. So Simple | Very Doge | Much Wow | Such Cool).
            separator: ",",
            // The delay between the changing of each phrase in milliseconds.
            speed: 3000,
            complete: function() {
                // Called after the entrance animation is executed.
            }
        });
    }

    /* ===================================
      Type Text
      ====================================== */

    if ($("#typewriting").length) {
        var app = document.getElementById("typewriting");
        var typewriter = new Typewriter(app, {
            loop: true
        });
        typewriter.typeString('Way to achieve success').pauseFor(2000).deleteAll()
            .typeString('Style to achieve success').pauseFor(2000).deleteAll()
            .typeString('Method to achieve success').start();
    }

    if ($("#personal").length) {
        var app = document.getElementById("personal");
        var personal = new Typewriter(app, {
            loop: true
        });
        personal.typeString('UI/UX Designer').pauseFor(2000).deleteAll()
            .typeString('Web Developer').pauseFor(2000).deleteAll()
            .typeString('Wordpress Developer').start();
    }

    /* =====================================
      Coming Soon Count Down
     ====================================== */


    /* =====================================
          Wow
     ======================================== */

    if ($(window).width() > 767) {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: true
        });
        new WOW().init();
    }


    /* ===================================
        Animated Cursor
     ====================================== */

    function animatedCursor() {

        if ($("#aimated-cursor").length) {

            var e = { x: 0, y: 0 },
                t = { x: 0, y: 0 },
                n = .25,
                o = !1,
                a = document.getElementById("cursor"),
                i = document.getElementById("cursor-loader");
            TweenLite.set(a, { xPercent: -50, yPercent: -50 }), document.addEventListener("mousemove", function(t) {
                    var n = window.pageYOffset || document.documentElement.scrollTop;
                    e.x = t.pageX, e.y = t.pageY - n
                }), TweenLite.ticker.addEventListener("tick", function() {
                    o || (t.x += (e.x - t.x) * n, t.y += (e.y - t.y) * n, TweenLite.set(a, { x: t.x, y: t.y }))
                }),
                $(".animated-wrap").mouseenter(function(e) {
                    TweenMax.to(this, .3, { scale: 2 }), TweenMax.to(a, .3, {
                        scale: 2,
                        borderWidth: "1px",
                        opacity: .2
                    }), TweenMax.to(i, .3, {
                        scale: 2,
                        borderWidth: "1px",
                        top: 1,
                        left: 1
                    }), TweenMax.to($(this).children(), .3, { scale: .5 }), o = !0
                }),
                $(".animated-wrap").mouseleave(function(e) {
                    TweenMax.to(this, .3, { scale: 1 }), TweenMax.to(a, .3, {
                        scale: 1,
                        borderWidth: "2px",
                        opacity: 1
                    }), TweenMax.to(i, .3, {
                        scale: 1,
                        borderWidth: "2px",
                        top: 0,
                        left: 0
                    }), TweenMax.to($(this).children(), .3, { scale: 1, x: 0, y: 0 }), o = !1
                }),
                $(".animated-wrap").mousemove(function(e) {
                    var n, o, i, l, r, d, c, s, p, h, x, u, w, f, m;
                    n = e, o = 2, i = this.getBoundingClientRect(), l = n.pageX - i.left, r = n.pageY - i.top, d = window.pageYOffset || document.documentElement.scrollTop, t.x = i.left + i.width / 2 + (l - i.width / 2) / o, t.y = i.top + i.height / 2 + (r - i.height / 2 - d) / o, TweenMax.to(a, .3, {
                        x: t.x,
                        y: t.y
                    }), s = e, p = c = this, h = c.querySelector(".animated-element"), x = 20, u = p.getBoundingClientRect(), w = s.pageX - u.left, f = s.pageY - u.top, m = window.pageYOffset || document.documentElement.scrollTop, TweenMax.to(h, .3, {
                        x: (w - u.width / 2) / u.width * x,
                        y: (f - u.height / 2 - m) / u.height * x,
                        ease: Power2.easeOut
                    })
                }),
                $(".hide-cursor,.btn,.tp-bullets").mouseenter(function(e) {
                    TweenMax.to("#cursor", .2, { borderWidth: "1px", scale: 2, opacity: 0 })
                }), $(".hide-cursor,.btn,.tp-bullets").mouseleave(function(e) {
                    TweenMax.to("#cursor", .3, { borderWidth: "2px", scale: 1, opacity: 1 })
                }), $(".link").mouseenter(function(e) {
                    TweenMax.to("#cursor", .2, {
                        borderWidth: "0px",
                        scale: 3,
                        backgroundColor: "rgba(255, 255, 255, 0.27)",
                        opacity: .15
                    })
                }), $(".link").mouseleave(function(e) {
                    TweenMax.to("#cursor", .3, {
                        borderWidth: "2px",
                        scale: 1,
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        opacity: 1
                    })
                })

        }

    }
    if ($(window).width() > 991) {
        setTimeout(function() {
            animatedCursor();
        }, 1000);
    }




    /* ===================================
          Owl Carousel
   ====================================== */

    //About Slider

    $("#laptop-slide").owlCarousel({
        items: 1,
        loop: true,
        dots: false,
        nav: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay: true,
        autoplayTimeout: 3000,
        // mouseDrag:false,
        responsive: {
            1280: {
                items: 1,
            },
            600: {
                items: 1,
            },
            320: {
                items: 1,
            },
        }
    });

    //App Slider

    $("#app-slider").owlCarousel({
        items: 1,
        loop: true,
        dots: false,
        nav: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay: true,
        autoplayTimeout: 3000,
        // mouseDrag:false,
        responsive: {
            1280: {
                items: 1,
            },
            600: {
                items: 1,
            },
            320: {
                items: 1,
            },
        }
    });

    // Team Slider

    $("#team-slider").owlCarousel({
        items: 3,
        dots: false,
        nav: false,
        responsive: {
            991: {
                items: 3,
            },
            767: {
                items: 2,
            },
            320: {
                items: 1,
            },
        }
    });

    //price slider

    $("#price-slider").owlCarousel({
        items: 3,
        dots: false,
        nav: false,
        responsive: {
            991: {
                items: 3,
            },
            767: {
                items: 2,
            },
            320: {
                items: 1,
            },
        }
    });

    $("#team-three-slider").owlCarousel({
        items: 4,
        dots: false,
        nav: false,
        responsive: {
            991: {
                items: 4,
            },
            767: {
                items: 2,
            },
            320: {
                items: 1,
            },
        }
    });

    //testimonial slider

    $("#testimonial_slider").owlCarousel({
        items: 1,
        dots: true,
        nav: false,
    });

    //single slider

    $("#single-slider").owlCarousel({
        items: 1,
        nav: false,
        loop: true,
        mouseDrag: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay: true,
        autoplayTimeout: 3000,

    });

    //team two slider

    $("#team-slider-two").owlCarousel({
        items: 1,
        nav: false,
        loop: true,
        dots: false,
        responsive: {
            991: {
                items: 2,
                autoplayHoverPause: true,
                autoplay: true,
                autoplayTimeout: 3000,
            },
            320: {
                items: 1,
            },
        }

    });


    //Contact Us
    // $("#submit_btn").click(function() {

    //     //disable submit button on click
    //     $("#submit_btn").attr("disabled", "disabled");
    //     $("#submit_btn span").text('Sending');
    //     $("#submit_btn i").removeClass('d-none');

    //     var user_name = $('input[name=first_name]').val() + ' ' + $('input[name=last_name]').val();
    //     var user_email = $('input[name=email]').val();
    //     var user_phone = $('input[name=phone]').val();
    //     var user_message = $('textarea[name=message]').val();

    //     //simple validation at client's end
    //     var post_data, output;
    //     var proceed = true;
    //     if (user_name == "") {
    //         proceed = false;
    //     }
    //     if (user_email == "") {
    //         proceed = false;
    //     }
    //     // if (user_phone == "") {
    //     //proceed = false;
    //     // }

    //     if (user_message == "") {
    //         proceed = false;
    //     }
    //     //everything looks good! proceed...
    //     if (proceed) {

    //         //data to be sent to server
    //         post_data = {
    //             'userName': user_name,
    //             'userEmail': user_email,
    //             'userPhone': user_phone,
    //             'userMessage': user_message
    //         };

    //         //Ajax post data to server
    //         $.post('contact.php', post_data, function(response) {

    //             //load json data from server and output message
    //             if (response.type == 'error') {
    //                 output = '<div class="alert-danger" style="padding:10px; margin-bottom:30px;">' + response.text + '</div>';
    //             } else {
    //                 output = '<div class="alert-success" style="padding:10px; margin-bottom:30px;">' + response.text + '</div>';

    //                 //reset values in all input fields
    //                 $('.contact-form input').val('');
    //                 $('.contact-form textarea').val('');
    //             }

    //             $("#result").hide().html(output).slideDown();

    //             // enable submit button on action done
    //             $("#submit_btn").removeAttr("disabled");
    //             $("#submit_btn span").text('Contact Now');
    //             $("#submit_btn i").addClass('d-none');

    //         }, 'json');

    //     } else {
    //         output = '<div class="alert-danger" style="padding:10px; margin-bottom:30px;">Please provide the missing fields.</div>';
    //         $("#result").hide().html(output).slideDown();

    //         // enable submit button on action done
    //         $("#submit_btn").removeAttr("disabled");
    //         $("#submit_btn span").text('Contact Now');
    //         $("#submit_btn i").addClass('d-none');
    //     }

    // });


    /*============================================*
            Cube Portfolio
  * ============================================*/


    // $('#js-grid-mosaic-flat').cubeportfolio({
    //     filters: '#js-filters-mosaic-flat',
    //     layoutMode: 'mosaic',
    //     sortByDimension: true,
    //     mediaQueries: [{
    //         width: 1500,
    //         cols: 6,
    //     }, {
    //         width: 1100,
    //         cols: 4,
    //     }, {
    //         width: 800,
    //         cols: 3,
    //     }, {
    //         width: 480,
    //         cols: 1,
    //         options: {
    //             gapHorizontal: 15,
    //             gapVertical: 15,
    //         }
    //     }],
    //     defaultFilter: '*',
    //     animationType: 'fadeOutTop',
    //     gapHorizontal: 0,
    //     gapVertical: 0,
    //     gridAdjustment: 'responsive',
    //     caption: 'zoom',

    //     // lightbox
    //     lightboxDelegate: '.cbp-lightbox',
    //     lightboxGallery: true,
    //     lightboxTitleSrc: 'data-title',

    //     plugins: {
    //         loadMore: {
    //             element: '#js-loadMore-mosaic-flat',
    //             action: 'click',
    //             loadItems: 3,
    //         }
    //     },
    // });


    /* ------ CubePortfolio ------ */

    $("#portfolio-measonry").cubeportfolio({
        filters: '#measonry-filters',
        loadMoreAction: 'click',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: "scaleSides",
        gapHorizontal: 30,
        gapVertical: 30,
        gridAdjustment: "responsive",
        mediaQueries: [{
            width: 1500,
            cols: 2
        }, {
            width: 1100,
            cols: 2
        }, {
            width: 768,
            cols: 2
        }, {
            width: 480,
            cols: 1
        }, {
            width: 320,
            cols: 1
        }],
    });


    /* ===================================
           Revolution Slider
    ====================================== */

    // $("#rev_slider_19_1").show().revolution({
    //     sliderType: "standard",
    //     jsFileLocation: "//localhost:82/revslider/revslider/public/assets/js/",
    //     sliderLayout: "fullscreen",
    //     dottedOverlay: "none",
    //     delay: 9000,
    //     navigation: {
    //         keyboardNavigation: "off",
    //         keyboard_direction: "horizontal",
    //         mouseScrollNavigation: "off",
    //         mouseScrollReverse: "default",
    //         onHoverStop: "off",
    //         bullets: {
    //             enable: true,
    //             hide_onmobile: true,
    //             hide_under: 767,
    //             style: "wexim",
    //             hide_onleave: false,
    //             direction: "vertical",
    //             h_align: "left",
    //             v_align: "center",
    //             h_offset: 30,
    //             v_offset: 0,
    //             space: 5,
    //             tmp: '<div class="tp-bullet-inner"></div><div class="tp-line"></div>'
    //         },
    //         touch: {
    //             touchenabled: "on",
    //             swipe_threshold: 75,
    //             swipe_min_touches: 1,
    //             swipe_direction: "horizontal",
    //             drag_block_vertical: false
    //         },
    //     },
    //     responsiveLevels: [1900, 1600, 1200, 1024, 778, 580],
    //     visibilityLevels: [1900, 1600, 1024, 778, 580],
    //     gridwidth: [1100, 1200, 1140, 960, 750, 480],
    //     gridheight: [868, 768, 960, 720],
    //     lazyType: "none",
    //     scrolleffect: {
    //         on_slidebg: "on",
    //     },
    //     parallax: {
    //         type: "mouse",
    //         origo: "slidercenter",
    //         speed: 2000,
    //         speedbg: 0,
    //         speedls: 0,
    //         levels: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    //         disable_onmobile: "on"
    //     },
    //     shadow: 0,
    //     spinner: "off",
    //     stopLoop: "off",
    //     stopAfterLoops: -1,
    //     stopAtSlide: -1,
    //     shuffle: "off",
    //     autoHeight: "off",
    //     fullScreenAutoWidth: "off",
    //     fullScreenAlignForce: "off",
    //     fullScreenOffsetContainer: "",
    //     fullScreenOffset: "",
    //     disableProgressBar: "on",
    //     hideThumbsOnMobile: "on",
    //     hideSliderAtLimit: 0,
    //     hideCaptionAtLimit: 0,
    //     hideAllCaptionAtLilmit: 0,
    //     debugMode: false,
    //     fallbacks: {
    //         simplifyAll: "off",
    //         nextSlideOnWindowFocus: "off",
    //         disableFocusListener: false,
    //     }
    // });

    /*animated elements hero banner*/
    // $("#rev_single").show().revolution({
    //     sliderType: "hero",
    //     jsFileLocation: "js/revolution",
    //     sliderLayout: "fullscreen",
    //     scrollbarDrag: "true",
    //     dottedOverlay: "none",
    //     delay: 9000,
    //     navigation: {},
    //     responsiveLevels: [1240, 1024, 778, 480],
    //     visibilityLevels: [1240, 1024, 778, 480],
    //     gridwidth: [1170, 1024, 778, 480],
    //     gridheight: [868, 768, 960, 720],
    //     lazyType: "none",
    //     parallax: {
    //         type: "scroll",
    //         origo: "slidercenter",
    //         speed: 400,
    //         levels: [10, 15, 20, 25, 30, 35, 40, -10, -15, -20, -25, -30, -35, -40, -45, 55]
    //     },
    //     shadow: 0,
    //     spinner: "off",
    //     autoHeight: "off",
    //     fullScreenAutoWidth: "off",
    //     fullScreenAlignForce: "off",
    //     fullScreenOffsetContainer: "",
    //     disableProgressBar: "on",
    //     hideThumbsOnMobile: "off",
    //     hideSliderAtLimit: 0,
    //     hideCaptionAtLimit: 0,
    //     hideAllCaptionAtLilmit: 0,
    //     debugMode: false,
    //     fallbacks: {
    //         simplifyAll: "off",
    //         disableFocusListener: false
    //     }
    // });

    // $("#rev_slider_1064_1").show().revolution({
    //     sliderType: "standard",
    //     jsFileLocation: "revolution/js/",
    //     sliderLayout: "fullscreen",
    //     dottedOverlay: "none",
    //     delay: 9000,
    //     navigation: {
    //         keyboardNavigation: "off",
    //         keyboard_direction: "horizontal",
    //         mouseScrollNavigation: "off",
    //         mouseScrollReverse: "default",
    //         onHoverStop: "off",
    //         touch: {
    //             touchenabled: "on",
    //             swipe_threshold: 75,
    //             swipe_min_touches: 1,
    //             swipe_direction: "vertical",
    //             drag_block_vertical: false
    //         }
    //     },
    //     responsiveLevels: [1240, 1024, 778, 480],
    //     visibilityLevels: [1240, 1024, 778, 480],
    //     gridwidth: [1240, 1024, 778, 480],
    //     gridheight: [868, 768, 960, 720],
    //     lazyType: "none",
    //     shadow: 0,
    //     spinner: "off",
    //     stopLoop: "on",
    //     stopAfterLoops: 0,
    //     stopAtSlide: 1,
    //     shuffle: "off",
    //     autoHeight: "off",
    //     fullScreenAutoWidth: "off",
    //     fullScreenAlignForce: "off",
    //     fullScreenOffsetContainer: ".header",
    //     fullScreenOffset: "",
    //     disableProgressBar: "on",
    //     hideThumbsOnMobile: "off",
    //     hideSliderAtLimit: 0,
    //     hideCaptionAtLimit: 0,
    //     hideAllCaptionAtLilmit: 0,
    //     debugMode: false,
    //     fallbacks: {
    //         simplifyAll: "off",
    //         nextSlideOnWindowFocus: "off",
    //         disableFocusListener: false,
    //     }
    // });



});